import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/Layout/MainLayout';
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu';
import chatApi from '../../api/chatApi';
import SlotContainer from '../../components/common/Slots/SlotContainer';
import TableSelectResearch from '../../components/PageBlocks/Research/Lab/LabDialog/TableSelectResearch';
import { getTestingstageTaskOnTask } from '../../api/chatApi/chatApi';
import Slot from '../../components/common/Slots/Slot';
import ButtonWithProgress from '../../components/common/ButtonWithProgress';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';



export default function LastAnalysesPage() {
  const [analyses, setAnalyses] = useState<[]>();
  const [specialist, setSpecialist] = useState<[]>();
  const dispatch = useDispatch();
  const [stages, setStages] = useState([]);
  const [spec, setSpec] = useState<any>([])

  const location = useLocation();
  const history = useHistory();

  const getSpec = async () => {
    try {
      const res = await chatApi.getAllSpecialists()
      setSpec(res.result);
    } catch (error) {
      console.log('Ошибка getAllSpecialists', error)
    }
  }

  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
    getSpec();
  }, []);

  async function getTaskProceedPastResearch() {
    try {
      const result = await chatApi.getTaskProceedPastResearch()
      setAnalyses(result.result)
    } catch (error) {
      console.log('Ошибка createRheumatologyProceedState', error)
    }
  }


  async function getTaskProceedPastSpecialist() {
    try {
      const result = await chatApi.getTaskProceedPastSpecialist()
      setSpecialist(result.result)

    } catch (error) {
      console.log('Ошибка createRheumatologyProceedState', error)
    }
  }

  useEffect(() => {
    getTaskProceedPastResearch()
    getTaskProceedPastSpecialist()
  }, [])

  // Функция для перехода на следующий этап
  async function goToTheNextStage() {
    setHistoryStage(8)
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    const currentActiveStage = currentStageObj.activeStage;
    const nextStageObj = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage)[0];

    if (nextStageObj) {
      history.push(nextStageObj.routePath);
    }
  }

  async function setHistoryStage(testingStage) {
    try {
      await chatApi.setTestingStageHistory(testingStage)
    } catch (error) {
      console.log('Ошибка setHistoryStage', error)
    }
  }


  return (
    <MainLayout
      desktopHeader={<NewChatWizardTopMenu title={'Прошлые анализы и специалисты'} />}
      title="Прошлые анализы и специалисты"
    >
      <SlotContainer>
        {analyses ? <Slot title='Прошлые анализы' color='primary'>
          {analyses ?
            <TableSelectResearch
              rows={
                analyses?.map((it: any) => {
                  let json: any | null = null;
                  if (it.value.indexOf('}') !== -1) {
                    const replacedText = it.value.replace(/\\\\n/g, '\\n').replace(/<\/?br>/g, '\\n').replace(/«/g, '"');
                    try {
                      json = JSON.parse(replacedText, (key, value) => {
                        if (typeof value === 'string') {
                          return value.trim();
                        }
                        return value;
                      });
                    } catch (error) {
                      console.error(`Ошибка при парсинге JSON: ${error.message}`);
                    }
                  } else {
                    return it;
                  }

                  if (json === null) {
                    return null;
                  }

                  return {
                    name: json.name || '',
                    value: json.value || '',
                    unit: json.unit || '',
                    reference: json.reference || ''
                  };
                })
              }
            /> : null}
        </Slot> : null}
        {specialist ? <Slot title='Прошлые специалисты' color='primary'>
          {specialist && specialist.map((el, itemIndex) => (
            <div key={itemIndex} style={{ padding: '10px 31px' }}>
              <div style={{ fontSize: '16px', fontWeight: 600 }}>{(spec && spec.length > 0) ? spec.filter(specName => specName.id === el.anamnesisId)[0].name : ''}
                <div style={{ fontSize: '1rem', fontWeight: 400 }}>
                  {el.value.split('/n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Slot> : null}
      </SlotContainer>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0px 0px 20px 0px',
          paddingBottom: "30px"
        }}
      >
        <ButtonWithProgress
          style={{}}
          variant='contained'
          color='primary'
          onClick={() => (goToTheNextStage())}
        >
          Следующий этап
        </ButtonWithProgress>
      </div>
    </MainLayout>
  )

}