import {
  createStyles,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core'
// eslint-disable-next-line
import React, { FunctionComponent } from 'react';
import { PatientStatusCode } from '../../../api/chatApi/chatApi';
import SlotCondition from '../../common/Slots/SlotCondition';


type Props = {
  value?: PatientStatusCode
  onChange: (newValue: PatientStatusCode) => void
  disabled?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    radioBtn: {
      alignItems: 'flex-start',
      '& span': {
        paddingTop: 0
      }
    },
    slot: {
      borderRadius: '10px 10px 0 0'
    },
    slotContainer: {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      background: '#fff',
      borderRadius: '20px',
      padding: '6px',
      height: '200px',
      width: '100%'
    },
    formControl: {
      border: 'none !important'
    }
  })
);

const PatientConditionSlot: FunctionComponent<Props> = (props) => {
  const classes = useStyles({} as Props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value as PatientStatusCode);
  };


  return (
    <div className={classes.slotContainer}>
      <SlotCondition title="Состояние пациента" color="attention">
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup aria-label="condition" name="condition1" value={props.value}
            onChange={handleChange}>
            <FormControlLabel
              className={classes.radioBtn}
              value={PatientStatusCode.Light}
              control={<Radio />}
              label="Удовлетворительное"
              checked={props.value === PatientStatusCode.Light}
              disabled={props.disabled}
            />
            <FormControlLabel
              className={classes.radioBtn}
              value={PatientStatusCode.Normal}
              control={<Radio />}
              label="Средней степени тяжести"
              checked={props.value === PatientStatusCode.Normal}
              disabled={props.disabled}
            />
            <FormControlLabel
              className={classes.radioBtn}
              value={PatientStatusCode.Hard}
              control={<Radio />}
              label="Тяжелое состояние"
              checked={props.value === PatientStatusCode.Hard}
              disabled={props.disabled}
            />
            <FormControlLabel
              className={classes.radioBtn}
              value={PatientStatusCode.Fatal}
              control={<Radio />}
              label="Крайне тяжелое состояние"
              checked={props.value === PatientStatusCode.Fatal}
              disabled={props.disabled}
            />


          </RadioGroup>
        </FormControl>
      </SlotCondition>
    </div>
  );
};

export default PatientConditionSlot;
