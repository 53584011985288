import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import chatApi from '../../../api/chatApi';
import RatingDialog from '../../Chat/RatingDialog';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import useIncreaseTaskStage from '../../../hooks/testingTask/useIncreaseTaskStage';
import { RoutePath } from '../../../types';
import MainLayout from "../../Layout/MainLayout";
import { Box, Divider, Modal, Backdrop, Fade, Typography, IconButton } from "@mui/material";
import DeadRat1 from '../../../assets/img/legend/DeadRat/DeadRat1.jpg';
import DeadRat2 from '../../../assets/img/legend/DeadRat/DeadRat2.jpg';
import DeadRat3 from '../../../assets/img/legend/DeadRat/DeadRat3.jpg';
import DeadRat4 from '../../../assets/img/legend/DeadRat/DeadRat4.jpg';
import DeadRat5 from '../../../assets/img/legend/DeadRat/DeadRat5.jpg';
import DeadRat26 from '../../../assets/img/legend/DeadRat/DeadRat26.jpg';
import Homunculus1 from '../../../assets/img/legend/DeadRat/homunculus1.png';
import CustomButton from "../../common/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import useTestingStage from "../../../hooks/testingTask/useTestingStage";

export default function LegendPage() {
  const [openRating, setOpenRating] = useState(false);
  const [isLegend, setIsLegend] = useState('');
  const [stages, setStages] = useState([]);
  const { routhPathCode, activeStage } = useNextStagePath(stages);
  const [increaseLoading, increase, setAsyncTaskStage] = useIncreaseTaskStage(RoutePath[routhPathCode], activeStage);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [title, setTitle] = useState('');


  const testingStage = useTestingStage()
  const location = useLocation();
  const history = useHistory();


  // Функция для перехода на следующий этап, если текущий этап уже пройден
  async function goToNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    const currentActiveStage = currentStageObj.activeStage;

    // Найти следующий этап с наименьшим activeStage, который больше текущего activeStage
    const nextStageObj = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage)[0];

    if (nextStageObj) {
      history.push(nextStageObj.routePath);
    }
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 600);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setOpenModal(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const getDialogStatus = async () => {
      const response = await chatApi.getSettingsPiloting();
      const statusValue = response.result[0].value;

      setStatus(statusValue === 'true');
    };

    getDialogStatus();
    setAsyncTaskStage();
  }, []);

  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
      const title = result.data.testingstage_task_on_task.find((oneStage) => {
        return oneStage.routePath === '/legend'
      })
      setTitle(title.testingstage_descr)
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  function useNextStagePath(stages) {
    const location = useLocation();
    const currentPath = location.pathname;

    return useMemo(() => {
      if (!stages || stages.length === 0) {
        return { routePath: '', routhPathCode: '' };
      }
      const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
      const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);

      if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
        return {
          routePath: sortedStages[currentIndex + 1].routePath,
          routhPathCode: sortedStages[currentIndex + 1].routhPathCode,
          activeStage: sortedStages[currentIndex + 1].activeStage
        };
      }

      return { routePath: '', routhPathCode: '', activeStage: '' };
    }, [stages, currentPath]);
  }

  useEffect(() => {
    getUnfinishedTask();
  }, []);

  async function getUnfinishedTask() {
    try {
      const currTask = await chatApi.unfinishedTask();
      setIsLegend(currTask.result.task.patientModel.legend);
    } catch (error) {
      console.log(error);
    }
  }

  function hideRatingDialog() {
    increase();
  }

  function onSubmitTask() {
    if (testingStage >= activeStage) {
      goToNextStage()
    } else {
      if (status) {
        setOpenRating(true);
      } else {
        increase();
      }
    }

  }

  function ExperimentsDisplay({ experiments }) {
    return (
      <div style={{ marginBottom: '50px' }}>
        {experiments.map((experiment, index) => (
          <div key={index}>
            {index > 0 && <Divider />}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginTop: 20, flexDirection: isMobile ? 'column' : 'row' }}>
              {experiment.title &&
                <div style={{ marginLeft: isMobile ? '0' : '20px', fontSize: '18px', marginBottom: isMobile ? '20px' : '0', width: isMobile ? '100%' : '55%', textAlign: isMobile ? 'center' : 'left', marginRight: isMobile ? '0' : '15px' }}>
                  <Typography variant="h6" gutterBottom>{experiment.title}</Typography>
                  <Typography variant="body1">{experiment.description}</Typography>
                </div>
              }
              {experiment.image && (
                <div style={{ flexShrink: 0, maxWidth: '800px', maxHeight: '500px', overflow: 'hidden', marginRight: isMobile ? '0' : '20px' }}>
                  <img
                    src={experiment.image}
                    alt={experiment.title}
                    style={{ width: '100%', height: 'auto', objectFit: 'contain', cursor: isMobile ? 'default' : 'pointer' }}
                    onClick={() => {
                      if (!isMobile) {
                        setSelectedImage(experiment.image);
                        setOpenModal(true);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  const experimentsDeadRat1 = [
    {
      title: 'Опыт А',
      description: `Лабораторное животное (мышь, крыса) помещено в небольшую барокамеру. В течение 2–3 мин из нее откачивают воздух, понижая в ней атмосферное давление примерно до 23 кПа. Через 0,5–1 мин пребывания в разреженной атмосфере животное проявляет признаки беспокойства: перебирает лапками, почёсывает мордочку, бегает по барокамере; ещё через 2–3 мин развиваются клонико–тонические судороги, мочеиспускание, животное лежит на боку, видны редкие дыхательные движения грудной клетки и брюшной стенки (признаки терминального дыхания «гаспинг»). Вскоре происходит полная остановка дыхания, животное погибает. Продолжительность жизни животного в разреженной атмосфере составляет, в среднем, 3 мин.`,
      image: DeadRat1
    },
    {
      title: 'Опыт Б',
      description: `Из барокамеры откачивают воздух до давления 4 кПа, после чего заполняют барокамеру чистым кислородом до нормального атмосферного давления. Приоткрыв дверцу барокамеры, помещают туда экспериментальное животное и немедленно вновь герметизируют камеру. В дальнейшем поступают так же, как и в опыте А, понижая атмосферное давление в камере примерно до 23 кПа при нормальном парциальном давлении кислорода в воздухе. Наблюдают за состоянием животного. Вначале у него возникает ориентировочная реакция; затем животное успокаивается. Каких-либо патологических явлений у него не развивается. Через 10 мин опыт прекращают и извлекают животное из камеры.`,
      image: DeadRat2
    },
    {
      title: 'Опыт В',
      description: `Барокамеру заполняют газовой смесью, состоящей из 95% азота и 5% кислорода (парциальное давление кислорода в такой смеси равно примерно 5 кПа) при нормальном атмосферном давлении. Помещают туда экспериментальное животное и продолжают пропускать струю указанной газовой смеси. Через 8 мин у животного развиваются судороги, остановка дыхания и оно погибает.`,
      image: DeadRat3
    },
    {
      title: 'Опыт Г',
      description: `Эксперимент проводится на 3 животных (мышь, крыса). Животное №1 подвергают умеренной физической нагрузке (плавание в аквариуме, температура воды около 30°C). Через 5 мин в тот же аквариум (для контроля нахождения в воде аквариума) помещают животное №2. Спустя 5 сек обоих животных извлекают из воды, помещают в барокамеру вместе с животным №3 (интактным) и откачивают из барокамеры воздух аналогично опыту А, понижая в ней атмосферное давление примерно до 23 кПа. Интактное животное мышь погибает через 3–4 мин, животное №2, находившееся в аквариуме пять секунд, спустя 5-6 мин, Животное №1 выдерживает пребывание в разреженной атмосфере барокамеры в течение 15 мин или более, после чего опыт прекращают и извлечённое из барокамеры животное остается живым.`,
      image: DeadRat4
    },
    {
      title: 'Опыт Д',
      description: `Эксперимент проводится на 3 животных. Животное №1 наркотизируют (например, внутрибрюшинным введением уретана). Это животное используют в опыте после развития у него глубокого наркоза. Животному №2 за 10 мин до опыта вводят стимулятор ЦНС (например, фенамин). Животное №3 служит контролем. Всех трёх животных помещают в барокамеру и откачивают воздух аналогично опыту А до атмосферного давления примерно в 23 кПа. Животное №2 (предварительное введение психостимулятора) обычно погибает на второй минуте пребывания в барокамере, в которой РАТМ равно 23 кПа, животное №3 (контрольное) на четвёртой - пятой минуте; животное №1 (наркотизированное уретаном) выдерживает, в среднем, около 15 мин (и более) гипобарической гипоксии. После этого его извлекают из барокамеры. У этого животного после пробуждения от наркоза не обнаруживается признаков заметного нарушения жизнедеятельности.`,
      image: DeadRat5
    },
  ];

  const experimentsDeadRat2 = [
    {
      title: 'Опыт',
      description: `С целью моделирования гемолитической анемии мышам внутрибрюшинно ввели раствор фенилгидразина. Через 50 мин после введения фенилгидразина в крови животных выявлены эритропения, лейкопения, свободные формы гемоглобина и метгемоглобина.`,
      image: DeadRat26
    },
  ];

  const experimentsHomunculus1 = [
    {
      image: Homunculus1
    },
  ];

  return (
    <MainLayout title={title}>
      <div>
        {openRating && <RatingDialog open={openRating} onSubmit={hideRatingDialog} />}
      </div>
      <div style={{ marginTop: 40 }}>
        {isLegend === 'dead_rat' && <ExperimentsDisplay experiments={experimentsDeadRat1} />}
        {isLegend === 'dead_rat_2' && <ExperimentsDisplay experiments={experimentsDeadRat2} />}
        {isLegend === 'homunculus1' && <ExperimentsDisplay experiments={experimentsHomunculus1} />}

        <CustomButton
          variant='contained'
          color='primary'
          disableElevation
          onClick={onSubmitTask}
        >
          {testingStage >= activeStage ? "Следующий этап" : 'Закончить этап'}
        </CustomButton>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxWidth: '85%',
            maxHeight: '85%',
            width: '80%',
            height: '80%',
            overflow: 'hidden',
            padding: '50px',
            '@media (max-width:600px)': {
              width: '95%',
              height: '95%',
              maxWidth: '100%',
              maxHeight: '100%',
              p: 2
            }
          }}>
            <IconButton
              aria-label="close"
              onClick={() => setOpenModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={selectedImage}
              alt="Увеличенное изображение"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '100%'
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </MainLayout>
  );
}
