import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { RoutePath } from '../../types'
import { Link } from 'react-router-dom'
import Location from '../../assets/img/mainPage/Location.svg'
import Email from '../../assets/img/mainPage/Email.svg'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapperFooter: {
      width: '100%',
      backgroundColor: '#F4F4F4',
      height: 308,
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'center',
      // position: 'sticky',

      // top: 'calc( 100vh - 170px )',
      // [theme.breakpoints.down('xs')]: {
      //   height: 200,
      //   top: 'calc( 50vh - 200px )'
      // }
    },
    blockLink: {
      maxWidth: 320,
      height: 308,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F4F4F4',
      padding: '60px 10px 0px 27px',
      [theme.breakpoints.down('xs')]: {
        height: 200,
        padding: 10
      }
    },
    blockSoc: {
      height: 308,
      width: '100%',
      padding: '0 5%',
      backgroundColor: '#F4F4F4',
      display: 'flex',
      // flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 42,
      [theme.breakpoints.down('xs')]: {
        height: 200
      },
      '@media (max-width: 800px)': {
        height: 510,
        flexDirection: 'column',
        alignItems: 'start',

      },
    },
    blockSoc1: {
      height: 308,
      width: '100%',
      whiteSpace: 'pre',
      backgroundColor: '#F4F4F4',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      padding: 42,
      [theme.breakpoints.down('xs')]: {
        height: 200
      }
    },
    link: {
      color: '#000000',
      textDecoration: 'none',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        margin: 14
      }
    },
    customLinkClass: {
      color: '#000000',
      fontSize: 16,
      lineHeight: '22px',
      marginBottom: 12,
      textDecoration: 'none',
      fontWeight: 600
    },
    img: {
      width: 22,
      height: 22,
      marginRight: 37,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        marginRight: 15
      }
    },
    text: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      [theme.breakpoints.down('xs')]: {
        fontSize: 25
      }
    }
  })
)

const MainFooter = () => {
  const style = useStyles()

  const links = [
    { name: 'Тренажер', link: 'newChat' },
    { name: 'Чемпионаты', link: 'championship' },
    { name: 'Методические материалы', link: 'methodological' },
    { name: 'Рейтинг студентов', link: 'rating' },
  ]

  return (
    <div className={style.wrapperFooter}>
      <div className={style.blockSoc}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '80%', width: 300 }}>
          <p style={{ fontSize: 22, fontFamily: 'Helvetica Neue', color: '#1E44CD', fontWeight: 500 }}>NeuroDoc</p>
          <p
            style={{ fontSize: 16, lineHeight: '22px', fontFamily: 'Helvetica Neue', }}>
            Мы делаем вклад в будущее медицины и обучаем специалистов
          </p>
          <div>
            <p style={{ paddingTop: 25 }}>© 2007 — 2023, ООО «С.К.А.Т.»</p>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', height: '80%' }}>
          {
            links.map(link => {
              return (
                <Link className={style.customLinkClass} to={link.link}>
                  {link.name}
                </Link>
              )
            })
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', height: '80%' }}>
          <Link className={style.customLinkClass} to={RoutePath.aboutCompany}>
            О компании
          </Link>
          {/* <Link className={style.customLinkClass} to={RoutePath.connection}>
            Связаться с нами
          </Link> */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', height: '80%' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <img src={Location} alt="Location" style={{ marginRight: 15 }} />
            <div>
              <p>г. Краснодар,</p>
              <p>350000, ул. Карасунская, 8</p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <img src={Email} alt="Email" style={{ marginRight: 15 }} />
            <p>info@skat-vending.com</p>
          </div>
        </div>
      </div>

      {/*<div className={style.blockSoc1}>*/}
      {/*  <div>*/}
      {/*    <img className={style.img} src={Insta} alt='Instagram' />*/}
      {/*    <img className={style.img} src={Youtube} alt='Youtube' />*/}
      {/*    <img className={style.img} src={W} alt='W' />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*иконки для социальных сетей*/}
    </div>
  )
}

export default MainFooter;
